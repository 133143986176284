import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { dispatchResizeEvent } from "..";
/**
 * A content got unblocked, let's run custom triggers on them, for example
 * the Elementor ready hook.
 */

function customUnblockTriggers(nodes) {
  var _ref = window,
      elementorFrontend = _ref.elementorFrontend,
      TCB_Front = _ref.TCB_Front,
      jQuery = _ref.jQuery,
      showGoogleMap = _ref.showGoogleMap,
      et_pb_init_modules = _ref.et_pb_init_modules,
      tdYoutubePlayers = _ref.tdYoutubePlayers,
      tdVimeoPlayers = _ref.tdVimeoPlayers;
  var foundWpgb = false;

  var _iterator = _createForOfIteratorHelper(nodes),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var node = _step.value.node;
      var className = node.className,
          id = node.id; // Elementor https://git.io/JsXoh

      elementorFrontend === null || elementorFrontend === void 0 ? void 0 : elementorFrontend.elementsHandler.runReadyTrigger(node); // WP Grid Builder

      if (id.startsWith("wpgb-") || className.startsWith("wpgb-")) {
        foundWpgb = true;
      }
    } // Thrive Architect

  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  TCB_Front === null || TCB_Front === void 0 ? void 0 : TCB_Front.handleIframes(TCB_Front.$body, true); // https://qodeinteractive.com/wordpress-theme/bridge-creative-multi-purpose-wordpress-theme/

  showGoogleMap === null || showGoogleMap === void 0 ? void 0 : showGoogleMap(); // jQuery plugins

  if (jQuery) {
    var _jQuery$lazyLoadXT, _jQuery;

    // https://github.com/ressio/lazy-load-xt
    (_jQuery$lazyLoadXT = (_jQuery = jQuery(window)).lazyLoadXT) === null || _jQuery$lazyLoadXT === void 0 ? void 0 : _jQuery$lazyLoadXT.call(_jQuery);
  } // Divi modules (e.g. Contact Forms and Newsletter signups)


  et_pb_init_modules === null || et_pb_init_modules === void 0 ? void 0 : et_pb_init_modules(); // tagDiv Composer

  tdYoutubePlayers === null || tdYoutubePlayers === void 0 ? void 0 : tdYoutubePlayers.init();
  tdVimeoPlayers === null || tdVimeoPlayers === void 0 ? void 0 : tdVimeoPlayers.init(); // WP Grid Builder (the custom event triggers an AJAX request, so do this only if we unblock a WPGB element)

  try {
    foundWpgb && window.dispatchEvent(new CustomEvent("wpgb.loaded"));
  } catch (e) {// Silence is golden.
  } // In some cases, the complete layout can be changed and calculations are based on containers width.
  // Imagine: Google Fonts get added and the navigation bar has a button with a calculated width (e.g. BeTheme).
  // Another example: The instagram feed is not loaded / adjusted (masonry), so we need to trigger a resize event manually


  dispatchResizeEvent();
}

export { customUnblockTriggers };