/* istanbul ignore file: we do not need to care about the entry point file as errors are detected through integration tests (E2E) */

/**
 * Entrypoint for plain website. This file is loaded on all pages.
 */
import { memorizeJQueryEvent } from "@devowl-wp/headless-content-unblocker";
import { delegateClickThroughUnblockedContent, listenOptInForContentBlocker, listenOptInJqueryFnForContentBlocker, overwriteElementorVideoApi } from "./others";
import { ready } from "./utils";
delegateClickThroughUnblockedContent();
listenOptInForContentBlocker();
listenOptInJqueryFnForContentBlocker(["fitVids", "mediaelementplayer"]);
overwriteElementorVideoApi();
ready(function () {
  memorizeJQueryEvent(document, document, "tve-dash.load"); // Thrive Leads

  memorizeJQueryEvent(document, document, "mylisting/single:tab-switched"); // My Listing theme
}, "interactive");